import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavbarService } from 'app/shared/navbar/navbar.service';



@Component({
    selector: 'app-kontakt',
    templateUrl: './kontakt.component.html',
    styleUrls: ['./kontakt.component.scss']
})

export class KontaktComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor( public nav: NavbarService ) {}

    ngOnInit() {
        this.nav.show();
    }
    ngAfterViewInit(){
      
    }
    ngOnDestroy(){
        
    }
}

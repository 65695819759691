import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Aktuelles } from 'app/interfaces/Aktuelles';
import { AktuellesService } from 'app/services/aktuelles.service';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { environment } from 'environments/environment';
import * as Rellax from 'rellax';


@Component({
    selector: 'app-aktuelles',
    templateUrl: './aktuelles.component.html',
    styleUrls: ['./aktuelles.component.scss']
})

export class AktuellesComponent implements OnInit, OnDestroy, AfterViewInit {

    aktuelles: Aktuelles[];
    aktuelles1 = [];
    backendURL= environment.apiUrl;
    display: boolean;

    constructor( public nav: NavbarService, private aktuellesSerive: AktuellesService ) {}

    ngOnInit() {
        this.nav.show();

        this.aktuellesSerive.getAktuelles()
            .subscribe(
                res => {
                    console.log(res);
                    this.aktuelles = res;

                    for (let i = 0; i <= res.length +1; ++i ) {
                        
                        if(res[i].online != environment.online) {
                          this.aktuelles1.push(res[i]);
                        }

                        if(this.aktuelles1.length == 0) {
                            this.display = true;
                        } else {
                            this.display = false;
                        }
                      }


                    console.log(this.aktuelles.length);

                    
                },
                err => {
                    console.log(err);
                }
            )

            if(this.aktuelles.length == 0) {
                this.display = true;
            } else {
                this.display = false;
            }
    }
    ngAfterViewInit(){
    }
    ngOnDestroy(){
       
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards-areas',
  templateUrl: './cards-areas.component.html',
  styleUrls: ['./cards-areas.component.scss']
})
export class CardsAreasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { Job } from 'app/_models/job';
import { JobService } from 'app/_services/job.service';
import { environment } from 'environments/environment';
import * as Rellax from 'rellax';


@Component({
    selector: 'app-stellenangebote',
    templateUrl: './stellenangebote.component.html',
    styleUrls: ['./stellenangebote.component.scss']
})

export class StellenangeboteComponent implements OnInit, OnDestroy, AfterViewInit {

   
    model = {
        left: true,
        middle: false,
        right: false
    };
    date : Date = new Date();
    job: Job[];
    job1 = [];
    display: boolean;

    constructor( public nav: NavbarService, private jobService: JobService ) {}

    ngOnInit() {
        this.nav.show();

        this.jobService.getJobs()
        .subscribe(
            res => {
                console.log(res);
                this.job = res;

                if(this.job.length == 0) {
                    this.display = true;
                }

                

                for (let i = 0; i <= res.length +1; i++) {
                    
                    if(res[i].online != environment.online) {

                      this.job1.push(res[i]);
                    }

                    console.log(this.job1.length);

                if(this.job1.length == 0) {
                    this.display = true;
                } else {
                    this.display = false;
                }
                }
                
            }, 
            err => {
                console.log(err);
            }
        )
    }

    ngAfterViewInit(){ }

    ngOnDestroy(){ }
}

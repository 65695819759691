import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavbarService } from 'app/shared/navbar/navbar.service';



@Component({
    selector: 'app-impressionen',
    templateUrl: './impressionen.component.html',
    styleUrls: ['./impressionen.component.scss']
})

export class ImpressionenComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor( public nav: NavbarService ) {}

    ngOnInit() {
        this.nav.show();
    }
    ngAfterViewInit(){
      
    }
    ngOnDestroy(){
        
    }
}

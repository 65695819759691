import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { AktuellesComponent } from './aktuelles.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AktuellesService } from 'app/services/aktuelles.service';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
    ],
    declarations: [ AktuellesComponent ],
    exports:[ AktuellesComponent ],
    providers: [AktuellesService]
})
export class AktuellesModule { }

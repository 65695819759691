import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { Notdienst } from 'app/_models/notdienst';
import { NotdienstService } from 'app/_services/notdienst.service';


@Component({
    selector: 'app-notdienst',
    templateUrl: './notdienst.component.html',
    styleUrls: ['./notdienst.component.scss']
})

export class NotdienstComponent implements OnInit, OnDestroy, AfterViewInit {

    notdienst: Notdienst[];
    notdienst1 = [];

    constructor( 
        public nav: NavbarService,
        private notdienstService: NotdienstService
         ) {}

    ngOnInit() {
        this.nav.show();
        this.notdienstService.getNotdienste()
        .subscribe(
            res => {
                console.log(res);
                this.notdienst = res;

                for (let i = 0; i <= res.length +1; i++) {
                    
                    if(res[i].online != false) {

                      this.notdienst1.push(res[i]);
                    }
                }
            },
            err => {
                console.log(err);
            }
        )
    }
    ngAfterViewInit(){
      
    }
    ngOnDestroy(){
        
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Aktuelles } from 'app/interfaces/Aktuelles';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AktuellesService {

  constructor(private http: HttpClient) { }

  getAktuelles(): Observable<Aktuelles[]>{
    return this.http.get<Aktuelles[]>(`${environment.apiUrl}/aktuelles`);
  }

  getLatest(): Observable<Aktuelles[]>{
    return this.http.get<Aktuelles[]>(`${environment.apiUrl}/aktuelles/latest`);
  }

  getAktuell(id: string): Observable<Aktuelles>{
    return this.http.get<Aktuelles>(`${environment.apiUrl}/aktuelles/aktuell/${id}`);
  }

  createAktuelles(aktuelles: Aktuelles): Observable<Aktuelles> {
    return this.http.post<Aktuelles>(`${environment.apiUrl}/aktuelles/create`, aktuelles);
  }

  deleteAktuelles(id: string): Observable<Aktuelles> {
    console.log(id);
    return this.http.delete<Aktuelles>(`${environment.apiUrl}/aktuelles/delete?aktuellesID=${id}`);
  }

  updateAktuelles(id: string, aktuelles: Aktuelles): Observable<Aktuelles> {
    return this.http.put<Aktuelles>(`${environment.apiUrl}/aktuelles/update?aktuellesID=${id}`, aktuelles);
  }

}

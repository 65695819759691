import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'environments/environment';
import { Notdienst } from 'app/_models/notdienst';

@Injectable({
  providedIn: 'root'
})
export class NotdienstService {

  constructor(private http: HttpClient) { }

  getNotdienste(): Observable<Notdienst[]>{
    return this.http.get<Notdienst[]>(`${environment.apiUrl}/notdienst`);
  }

  getNotdienst(id: string): Observable<Notdienst>{
    return this.http.get<Notdienst>(`${environment.apiUrl}/notdienst/${id}`);
  }

  createNotdienst(notdienst: Notdienst): Observable<Notdienst> {
    return this.http.post<Notdienst>(`${environment.apiUrl}/notdienst/create`, notdienst);
  }

  deleteNotdienst(id: string): Observable<Notdienst> {
    console.log(id);
    return this.http.delete<Notdienst>(`${environment.apiUrl}/notdienst/delete?notdienstID=${id}`);
  }

  updateNotdienst(id: string, notdienst: Notdienst): Observable<Notdienst> {
    return this.http.put<Notdienst>(`${environment.apiUrl}/notdienst/update?notdienstID=${id}`, notdienst);
  }

}
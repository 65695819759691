import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { PresentationComponent } from './presentation/presentation.component';
import { AboutusComponent } from './examples/aboutus/aboutus.component';
import { AktuellesComponent } from './aktuelles/aktuelles.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { StellenangeboteComponent } from './stellenangebote/stellenangebote.component';
import { ImpressionenComponent } from './impressionen/impressionen.component';
import { NotdienstComponent } from './notdienst/notdienst.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helper/auth.guard';
import {Aktuelles_listComponent} from './aktuelles_list/aktuelles_list.component';
import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes = [
    { path: 'presentation', component: PresentationComponent},
    { path: 'aktuelles', component: AktuellesComponent},
    { path: 'aktuelles/list/:id', component: Aktuelles_listComponent},
    { path: 'leistungen', component: LeistungenComponent},
    { path: 'kontakt', component: KontaktComponent},
    { path: 'stellenangebote', component: StellenangeboteComponent},
    { path: 'notdienst', component: NotdienstComponent},
    { path: 'team',     component: AboutusComponent},
    { path: 'datenschutz', component: DatenschutzComponent},
    { path: 'impressum', component: ImpressumComponent},
    //{ path: 'login', component: LoginComponent},

    { path: '', redirectTo: 'presentation', pathMatch: 'full' }

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{
          useHash: true
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }

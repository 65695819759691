import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { Aktuelles } from 'app/interfaces/Aktuelles';
import { AktuellesService } from 'app/services/aktuelles.service';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { environment } from 'environments/environment';
import * as Rellax from 'rellax';
import {ActivatedRoute} from "@angular/router";


@Component({
    selector: 'app-aktuelles',
    templateUrl: './aktuelles_list.component.html',
    styleUrls: ['./aktuelles_list.component.scss']
})

export class Aktuelles_listComponent implements OnInit, OnDestroy, AfterViewInit {

    aktuell: Aktuelles;
    aktuelles1 = [];
    backendURL= environment.apiUrl;
    display: boolean;

    constructor( public nav: NavbarService, private aktuellesSerive: AktuellesService, private activatedRoute: ActivatedRoute ) {}

    ngOnInit() {
        this.nav.show();

        const params = this.activatedRoute.snapshot.params;
        if (params.id) {
            this.aktuellesSerive.getAktuell(params.id)
                .subscribe(
                    res => {
                        console.log(res);
                        this.aktuell = res;
                    },
                    err => {
                        console.log(err);
                    }
                )
        }


    }

    @ViewChild('testSpan') private testSpan: ElementRef<HTMLElement>;
    ngAfterViewInit(){
    this.testSpan.nativeElement.innerHTML = this.aktuell.text;
    }
    ngOnDestroy(){
       
    }
}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AktuellesService } from 'app/services/aktuelles.service';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import * as Rellax from 'rellax';


@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.component.html',
    styleUrls: ['./impressum.component.scss']
})

export class ImpressumComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor( 
        public nav: NavbarService
    ) {}

    ngOnInit() {
        this.nav.show();        
    }
    ngAfterViewInit(){
    }
    ngOnDestroy(){
       
    }
}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Aktuelles } from 'app/interfaces/Aktuelles';
import { AktuellesService } from 'app/services/aktuelles.service';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { Home } from 'app/_models/home';
import { HomeService } from 'app/_services/home.service';
import { environment } from 'environments/environment';
import * as Rellax from 'rellax';


@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})

export class PresentationComponent implements OnInit, AfterViewInit {
    model = {
        left: true,
        middle: false,
        right: false
    };
    date : Date = new Date();
    display_nav: boolean;

    aktuelles: Aktuelles[];
    aktuelles1 = [];
    home: Home[];
    text: string;
    display: Boolean;

    backendURL = environment.apiUrl;


     constructor(private nav: NavbarService, private aktuellesService: AktuellesService, private homeService: HomeService) { }

    ngOnInit() {
        this.aktuellesService.getLatest()
        .subscribe(
            res => {
                console.log(res);
                this.aktuelles = res;

                if(res.length == 0) {
                    this.display = true;
                } else {
                    this.display = false;
                }

                for (let i = 0; i <= res.length +1; ++i ) {
                        
                    if(res[i].online != environment.online) {
                      this.aktuelles1.push(res[i]);
                    }

                    if(this.aktuelles1.length == 0) {
                        this.display = true;
                    } else {
                        this.display = false;
                    }
                }

            },
            err => {
                console.log(err);
            }
        );

        this.homeService.get()
        .subscribe(
            abc => {
                console.log(abc);
                this.home = abc;
                this.text = this.home[0].text;
            },
            err => {
                console.log(err);
            }
        )

        if(window.screen.width <= 425) {
            this.nav.show();
            this.display_nav = false;
        } else {
            this.nav.hide();
            this.display_nav = true;
        }
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('presentation-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');

        


    }
    ngAfterViewInit(){
      setTimeout(function(){
        if (window.innerWidth >= 991) {
            var rellax = new Rellax('.rellax', {
                center: true
            });
            var rellax1 = new Rellax('.rellax1', {
                center: true
            });
            var rellax5 = new Rellax('.rellax5', {
                center: true
            });
            var rellax6 = new Rellax('.rellax6', {
                center: true
            });
            var rellax7 = new Rellax('.rellax7', {
                center: true
            });
            var rellax8 = new Rellax('.rellax8', {
                center: true
            });
            var rellax9 = new Rellax('.rellax9', {
                center: true
            });
            var rellax10 = new Rellax('.rellax10', {
                center: true
            });
            var rellax11 = new Rellax('.rellax11', {
                center: true
            });
            var rellax12 = new Rellax('.rellax12', {
                center: true
            });
            var rellax13 = new Rellax('.rellax13', {
                center: true
            });
            var rellax14 = new Rellax('.rellax14', {
                center: true
            });

            var rellaxHeader = new Rellax('.rellax-header');
            var rellaxText = new Rellax('.rellax-text');
        }
      },200);

    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('presentation-page');
        
    }
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { SectionsModule } from './sections/sections.module';
import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';

import { AppComponent } from './app.component';
import { PresentationComponent } from './presentation/presentation.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { PresentationModule } from './presentation/presentation.module';
import { NavbarService } from './shared/navbar/navbar.service';
import { AktuellesModule } from './aktuelles/aktuelles.module';
import { LeistungenModule } from './leistungen/leistungen.module';
import { KontaktModule } from './kontakt/kontakt.module';
import { StellenangeboteModule } from './stellenangebote/stellenangebote.module';
import { ImpressionenModule } from './impressionen/impressionen.module';
import { NotdienstModule } from './notdienst/notdienst.module';
import { HttpClientModule } from '@angular/common/http';
import { DatenschutzModule } from './datenschutz/datenschutz.module';
import { LoginModule } from './login/login.module';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import {Aktuelles_listModule} from "./aktuelles_list/aktuelles_list.module";
import { ImpressumModule } from './impressum/impressum.module';


const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain: 'zahnarzt-wolf-has.de'
    },
    palette: {
      popup: {
        background: '#000'
      },
      button: {
        background: '#f1d600'
      }
    },
    theme: 'edgeless',
    type: 'opt-out'
  };


@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        PresentationModule,
        AktuellesModule,
        Aktuelles_listModule,
        LeistungenModule,
        DatenschutzModule,
        KontaktModule,
        StellenangeboteModule,
        DatenschutzModule,
        ImpressionenModule,
        NotdienstModule,
        SectionsModule,
        ComponentsModule,
        ExamplesModule,
        HttpClientModule,
        LoginModule,
        ImpressumModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [NavbarService],
    bootstrap: [AppComponent]
})
export class AppModule { }

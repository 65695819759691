import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Leistungen } from 'app/interfaces/Leistungen';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeistungenService {


  constructor(private http: HttpClient) { }

  getLeistungen(): Observable<Leistungen[]>{
    return this.http.get<Leistungen[]>(`${environment.apiUrl}/leistungen`);
  }

  getLeistung(id: string): Observable<Leistungen>{
    return this.http.get<Leistungen>(`${environment.apiUrl}/leistungen/${id}`);
  }

  createLeistungen(leistungen: Leistungen): Observable<Leistungen> {
    return this.http.post<Leistungen>(`${environment.apiUrl}/leistungen/create`, leistungen);
  }

  deleteLeistungen(id: string): Observable<Leistungen> {
    console.log(id);
    return this.http.delete<Leistungen>(`${environment.apiUrl}/leistungen/delete?leistungenID=${id}`);
  }

  updateLeistungen(id: string, leistungen: Leistungen): Observable<Leistungen> {
    return this.http.put<Leistungen>(`${environment.apiUrl}/leistungen/update?leistungenID=${id}`, leistungen);
  }

}
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Aktuelles } from 'app/interfaces/Aktuelles';
import { AktuellesService } from 'app/services/aktuelles.service';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { Datenschutz } from 'app/_models/datenschutz';
import { DatenschutzService } from 'app/_services/datenschutz.service';
import * as Rellax from 'rellax';


@Component({
    selector: 'app-datenschutz',
    templateUrl: './datenschutz.component.html',
    styleUrls: ['./datenschutz.component.scss']
})

export class DatenschutzComponent implements OnInit, OnDestroy, AfterViewInit {

    datenschutz: Datenschutz[];

    constructor( 
        public nav: NavbarService
    ) {}

    ngOnInit() {
        this.nav.show();        
    }
    ngAfterViewInit(){
    }
    ngOnDestroy(){
       
    }
}

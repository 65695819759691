import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AktuellesService } from 'app/services/aktuelles.service';
import { DatenschutzComponent } from './datenschutz.component';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
    ],
    declarations: [ DatenschutzComponent ],
    exports:[ DatenschutzComponent ],
    providers: [AktuellesService]
})
export class DatenschutzModule { }

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgxGalleryThumbnailsComponent } from '@kolkov/ngx-gallery';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Leistungen } from 'app/interfaces/Leistungen';
import { LeistungenService } from 'app/services/leistungen.services';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { environment } from 'environments/environment';
import * as Rellax from 'rellax';


@Component({
    selector: 'app-leistungen',
    templateUrl: './leistungen.component.html',
    styleUrls: ['./leistungen.component.scss']
})

export class LeistungenComponent implements OnInit, OnDestroy, AfterViewInit {

  closeResult = '';
  
  leistungen: Leistungen[];
  leistungen1 = [];
  abc: any;

    constructor( public nav: NavbarService, private leistungenService: LeistungenService, private modalService: NgbModal ) {}

    ngOnInit() {
        this.nav.show();

        this.leistungenService.getLeistungen()
            .subscribe(
                res => {
                    console.log(res);
                    this.leistungen = res;

                    for (let i = 0; i <= res.length +1; ++i ) {
                      console.log(this.leistungen[i].online);
                      
                      if(res[i].online != environment.online) {
                        this.leistungen1.push(res[i]);
                      }
                    }
                },
                err => {
                    console.log(err);
                }
            )
    }

    open(content, id) {      
      this.leistungenService.getLeistung(id)
        .subscribe(
          res => {
            console.log(res);
            this.abc = res;
          },
          err => console.log(err)
        )


      this.modalService.open(content);
    }


    ngAfterViewInit(){
    }
    ngOnDestroy(){
       
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Job } from 'app/_models/job';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) { }

  getJobs(): Observable<Job[]>{
    return this.http.get<Job[]>(`${environment.apiUrl}/job`);
  }

  getJob(id: string): Observable<Job>{
    return this.http.get<Job>(`${environment.apiUrl}/job/${id}`);
  }

  createJob(job: Job): Observable<Job> {
    return this.http.post<Job>(`${environment.apiUrl}/job/create`, job);
  }

  deleteJob(id: string): Observable<Job> {
    console.log(id);
    return this.http.delete<Job>(`${environment.apiUrl}/job/delete?jobID=${id}`);
  }

  updateJob(id: string, job: Job): Observable<Job> {
    return this.http.put<Job>(`${environment.apiUrl}/job/update?jobID=${id}`, job);
  }

}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NavbarService } from 'app/shared/navbar/navbar.service';
import * as Rellax from 'rellax';
import {TeamService} from "../../_services/team.services";
import {Team} from "../../_models/Team";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class AboutusComponent implements OnInit {
    
    team: Team[];
    team1: Team;
    backendURL = environment.apiUrl;

    constructor( 
      public nav: NavbarService,
      config: NgbModalConfig,
      private modalService: NgbModal,
      private teamService: TeamService
       ) {
        config.backdrop = 'static';
        config.keyboard = false;
        }

    ngOnInit() {
        this.nav.show();

        this.teamService.getTeams()
            .subscribe(
                res => {
                    console.log(res);
                    this.team = res;
                },
                err => {
                    console.log(err);
                }
            )
    }

    open_model(content, id) {
        this.teamService.getTeam(id)
            .subscribe(
                res => {
                    console.log(res);
                    this.team1 = res;
                }
            )
      this.modalService.open(content);
    }

}

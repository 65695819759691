import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import { NavbarService } from 'app/shared/navbar/navbar.service';
import { AuthenticationService } from 'app/_services/authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    data: Date = new Date();
    loginForm: any;

    // tslint:disable-next-line:max-line-length
    constructor(public nav: NavbarService, private formbuilder: FormBuilder, private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute,) {
        this.loginForm = this.formbuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.nav.hide();
    }

    onSubmit() {

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        console.log(this.loginForm.value.email);
        console.log(this,this.loginForm.value.password);

        this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password)
            .pipe(first())
            .subscribe({
                next: () => {
                    // get return url from route parameters or default to '/'
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    this.router.navigate([returnUrl]);
                    this.nav.show();
                },
                error: error => {
                    console.log(error);
                }
            });
    }
}

